const countryCodesWithImage = [
  "e06000001",
  "e06000002",
  "e06000003",
  "e06000004",
  "e06000005",
  "e06000006",
  "e06000007",
  "e06000008",
  "e06000009",
  "e06000010",
  "e06000011",
  "e06000012",
  "e06000013",
  "e06000014",
  "e06000015",
  "e06000016",
  "e06000017",
  "e06000018",
  "e06000019",
  "e06000020",
  "e06000021",
  "e06000022",
  "e06000023",
  "e06000024",
  "e06000025",
  "e06000026",
  "e06000027",
  "e06000030",
  "e06000031",
  "e06000032",
  "e06000033",
  "e06000034",
  "e06000035",
  "e06000036",
  "e06000037",
  "e06000038",
  "e06000039",
  "e06000040",
  "e06000041",
  "e06000042",
  "e06000043",
  "e06000044",
  "e06000045",
  "e06000046",
  "e06000047",
  "e06000049",
  "e06000050",
  "e06000051",
  "e06000052",
  "e06000053",
  "e06000054",
  "e06000055",
  "e06000056",
  "e06000057",
  "e06000058",
  "e06000059",
  "e06000060",
  "e06000061",
  "e06000062",
  "e07000008",
  "e07000009",
  "e07000010",
  "e07000011",
  "e07000012",
  "e07000026",
  "e07000027",
  "e07000028",
  "e07000029",
  "e07000030",
  "e07000031",
  "e07000032",
  "e07000033",
  "e07000034",
  "e07000035",
  "e07000036",
  "e07000037",
  "e07000038",
  "e07000039",
  "e07000040",
  "e07000041",
  "e07000042",
  "e07000043",
  "e07000044",
  "e07000045",
  "e07000046",
  "e07000047",
  "e07000061",
  "e07000062",
  "e07000063",
  "e07000064",
  "e07000065",
  "e07000066",
  "e07000067",
  "e07000068",
  "e07000069",
  "e07000070",
  "e07000071",
  "e07000072",
  "e07000073",
  "e07000074",
  "e07000075",
  "e07000076",
  "e07000077",
  "e07000078",
  "e07000079",
  "e07000080",
  "e07000081",
  "e07000082",
  "e07000083",
  "e07000084",
  "e07000085",
  "e07000086",
  "e07000087",
  "e07000088",
  "e07000089",
  "e07000090",
  "e07000091",
  "e07000092",
  "e07000093",
  "e07000094",
  "e07000095",
  "e07000096",
  "e07000098",
  "e07000099",
  "e07000102",
  "e07000103",
  "e07000105",
  "e07000106",
  "e07000107",
  "e07000108",
  "e07000109",
  "e07000110",
  "e07000111",
  "e07000112",
  "e07000113",
  "e07000114",
  "e07000115",
  "e07000116",
  "e07000117",
  "e07000118",
  "e07000119",
  "e07000120",
  "e07000121",
  "e07000122",
  "e07000123",
  "e07000124",
  "e07000125",
  "e07000126",
  "e07000127",
  "e07000128",
  "e07000129",
  "e07000130",
  "e07000131",
  "e07000132",
  "e07000133",
  "e07000134",
  "e07000135",
  "e07000136",
  "e07000137",
  "e07000138",
  "e07000139",
  "e07000140",
  "e07000141",
  "e07000142",
  "e07000143",
  "e07000144",
  "e07000145",
  "e07000146",
  "e07000147",
  "e07000148",
  "e07000149",
  "e07000163",
  "e07000164",
  "e07000165",
  "e07000166",
  "e07000167",
  "e07000168",
  "e07000169",
  "e07000170",
  "e07000171",
  "e07000172",
  "e07000173",
  "e07000174",
  "e07000175",
  "e07000176",
  "e07000177",
  "e07000178",
  "e07000179",
  "e07000180",
  "e07000181",
  "e07000187",
  "e07000188",
  "e07000189",
  "e07000192",
  "e07000193",
  "e07000194",
  "e07000195",
  "e07000196",
  "e07000197",
  "e07000198",
  "e07000199",
  "e07000200",
  "e07000202",
  "e07000203",
  "e07000207",
  "e07000208",
  "e07000209",
  "e07000210",
  "e07000211",
  "e07000212",
  "e07000213",
  "e07000214",
  "e07000215",
  "e07000216",
  "e07000217",
  "e07000218",
  "e07000219",
  "e07000220",
  "e07000221",
  "e07000222",
  "e07000223",
  "e07000224",
  "e07000225",
  "e07000226",
  "e07000227",
  "e07000228",
  "e07000229",
  "e07000234",
  "e07000235",
  "e07000236",
  "e07000237",
  "e07000238",
  "e07000239",
  "e07000240",
  "e07000241",
  "e07000242",
  "e07000243",
  "e07000244",
  "e07000245",
  "e07000246",
  "e08000001",
  "e08000002",
  "e08000003",
  "e08000004",
  "e08000005",
  "e08000006",
  "e08000007",
  "e08000008",
  "e08000009",
  "e08000010",
  "e08000011",
  "e08000012",
  "e08000013",
  "e08000014",
  "e08000015",
  "e08000016",
  "e08000017",
  "e08000018",
  "e08000019",
  "e08000021",
  "e08000022",
  "e08000023",
  "e08000024",
  "e08000025",
  "e08000026",
  "e08000027",
  "e08000028",
  "e08000029",
  "e08000030",
  "e08000031",
  "e08000032",
  "e08000033",
  "e08000034",
  "e08000035",
  "e08000036",
  "e08000037",
  "e09000001",
  "e09000002",
  "e09000003",
  "e09000004",
  "e09000005",
  "e09000006",
  "e09000007",
  "e09000008",
  "e09000009",
  "e09000010",
  "e09000011",
  "e09000012",
  "e09000013",
  "e09000014",
  "e09000015",
  "e09000016",
  "e09000017",
  "e09000018",
  "e09000019",
  "e09000020",
  "e09000021",
  "e09000022",
  "e09000023",
  "e09000024",
  "e09000025",
  "e09000026",
  "e09000027",
  "e09000028",
  "e09000029",
  "e09000030",
  "e09000031",
  "e09000032",
  "e09000033",
  "n09000001",
  "n09000002",
  "n09000003",
  "n09000004",
  "n09000005",
  "n09000006",
  "n09000007",
  "n09000008",
  "n09000009",
  "n09000010",
  "n09000011",
  "s12000005",
  "s12000006",
  "s12000008",
  "s12000010",
  "s12000011",
  "s12000013",
  "s12000014",
  "s12000017",
  "s12000018",
  "s12000019",
  "s12000020",
  "s12000021",
  "s12000023",
  "s12000026",
  "s12000027",
  "s12000028",
  "s12000029",
  "s12000030",
  "s12000033",
  "s12000034",
  "s12000035",
  "s12000036",
  "s12000038",
  "s12000039",
  "s12000040",
  "s12000041",
  "s12000042",
  "s12000045",
  "s12000047",
  "s12000048",
  "s12000049",
  "s12000050",
  "w06000001",
  "w06000002",
  "w06000003",
  "w06000004",
  "w06000005",
  "w06000006",
  "w06000008",
  "w06000009",
  "w06000010",
  "w06000011",
  "w06000012",
  "w06000013",
  "w06000014",
  "w06000015",
  "w06000016",
  "w06000018",
  "w06000019",
  "w06000020",
  "w06000021",
  "w06000022",
  "w06000023",
  "w06000024",
];
export interface Country {
  code: string;
  latitude: number;
  longitude: number;
  name: string;
}
export const countries: Country[] = [
  {
    code: "E06000001",
    latitude: 54.669024195334906,
    longitude: -1.2556649025120736,
    name: "Hartlepool",
  },
  {
    code: "E06000002",
    latitude: 54.54248982351388,
    longitude: -1.2224442845221546,
    name: "Middlesbrough",
  },
  {
    code: "E06000003",
    latitude: 54.55362675685401,
    longitude: -1.0216621755367932,
    name: "Redcar and Cleveland",
  },
  {
    code: "E06000004",
    latitude: 54.56275363948177,
    longitude: -1.3289928056953744,
    name: "Stockton-on-Tees",
  },
  {
    code: "E06000005",
    latitude: 54.548710146553695,
    longitude: -1.5525960791488373,
    name: "Darlington",
  },
  {
    code: "E06000006",
    latitude: 53.343765151605034,
    longitude: -2.7187981365073313,
    name: "Halton",
  },
  {
    code: "E06000007",
    latitude: 53.398825264550496,
    longitude: -2.56362606792093,
    name: "Warrington",
  },
  {
    code: "E06000008",
    latitude: 53.692628213552176,
    longitude: -2.4675455200686938,
    name: "Blackburn with Darwen",
  },
  {
    code: "E06000009",
    latitude: 53.81767725802808,
    longitude: -3.0355222553699384,
    name: "Blackpool",
  },
  {
    code: "E06000010",
    latitude: 53.75993402629093,
    longitude: -0.3353822534200109,
    name: "Kingston upon Hull, City of",
  },
  {
    code: "E06000011",
    latitude: 53.86806254699912,
    longitude: -0.4824656905049224,
    name: "East Riding of Yorkshire",
  },
  {
    code: "E06000012",
    latitude: 53.54399292105833,
    longitude: -0.13344958925594125,
    name: "North East Lincolnshire",
  },
  {
    code: "E06000013",
    latitude: 53.59435171689672,
    longitude: -0.5925037811303291,
    name: "North Lincolnshire",
  },
  {
    code: "E06000014",
    latitude: 53.95873740890825,
    longitude: -1.0614584890253604,
    name: "York",
  },
  {
    code: "E06000015",
    latitude: 52.91256297670638,
    longitude: -1.4683400636351727,
    name: "Derby",
  },
  {
    code: "E06000016",
    latitude: 52.63793261806233,
    longitude: -1.1284776613640106,
    name: "Leicester",
  },
  {
    code: "E06000017",
    latitude: 52.66074193942325,
    longitude: -0.6546791449860094,
    name: "Rutland",
  },
  {
    code: "E06000018",
    latitude: 52.960605152884135,
    longitude: -1.1765171677768127,
    name: "Nottingham",
  },
  {
    code: "E06000019",
    latitude: 52.10348787012399,
    longitude: -2.744960937068262,
    name: "Herefordshire, County of",
  },
  {
    code: "E06000020",
    latitude: 52.730210039257045,
    longitude: -2.4887933007743395,
    name: "Telford and Wrekin",
  },
  {
    code: "E06000021",
    latitude: 53.018036468951834,
    longitude: -2.1645275032214966,
    name: "Stoke-on-Trent",
  },
  {
    code: "E06000022",
    latitude: 51.35598169868612,
    longitude: -2.475255967305343,
    name: "Bath and North East Somerset",
  },
  {
    code: "E06000023",
    latitude: 51.45274253711703,
    longitude: -2.770842586757075,
    name: "Bristol, City of",
  },
  {
    code: "E06000024",
    latitude: 51.38914922549056,
    longitude: -2.8009053399443844,
    name: "North Somerset",
  },
  {
    code: "E06000025",
    latitude: 51.54685516196917,
    longitude: -2.4705485020208497,
    name: "South Gloucestershire",
  },
  {
    code: "E06000026",
    latitude: 50.39175567382672,
    longitude: -4.119946886349249,
    name: "Plymouth",
  },
  {
    code: "E06000027",
    latitude: 50.44060412812934,
    longitude: -3.5302247150394983,
    name: "Torbay",
  },
  {
    code: "E06000030",
    latitude: 51.57151904461583,
    longitude: -1.743800356817968,
    name: "Swindon",
  },
  {
    code: "E06000031",
    latitude: 52.608577443487796,
    longitude: -0.2530947860266939,
    name: "Peterborough",
  },
  {
    code: "E06000032",
    latitude: 51.894233384003925,
    longitude: -0.42556557400844763,
    name: "Luton",
  },
  {
    code: "E06000033",
    latitude: 51.53805186524607,
    longitude: 0.728625529011949,
    name: "Southend-on-Sea",
  },
  {
    code: "E06000034",
    latitude: 51.50598478999274,
    longitude: 0.3732339440794984,
    name: "Thurrock",
  },
  {
    code: "E06000035",
    latitude: 51.425687663829336,
    longitude: 0.5695257876926285,
    name: "Medway",
  },
  {
    code: "E06000036",
    latitude: 51.408113494187205,
    longitude: -0.7397204747359332,
    name: "Bracknell Forest",
  },
  {
    code: "E06000037",
    latitude: 51.44962811030744,
    longitude: -1.3044171065484502,
    name: "West Berkshire",
  },
  {
    code: "E06000038",
    latitude: 51.452980112829415,
    longitude: -0.983804212435116,
    name: "Reading",
  },
  {
    code: "E06000039",
    latitude: 51.50840081139076,
    longitude: -0.5862174068019033,
    name: "Slough",
  },
  {
    code: "E06000040",
    latitude: 51.48567437604814,
    longitude: -0.6994613651881139,
    name: "Windsor and Maidenhead",
  },
  {
    code: "E06000041",
    latitude: 51.429406896696484,
    longitude: -0.8838235896667633,
    name: "Wokingham",
  },
  {
    code: "E06000042",
    latitude: 52.08381506169587,
    longitude: -0.7335202641850187,
    name: "Milton Keynes",
  },
  {
    code: "E06000043",
    latitude: 50.84347923825571,
    longitude: -0.13310787995406928,
    name: "Brighton and Hove",
  },
  {
    code: "E06000044",
    latitude: 50.82043375364501,
    longitude: -1.0783617175712028,
    name: "Portsmouth",
  },
  {
    code: "E06000045",
    latitude: 50.91698716623794,
    longitude: -1.3987048570044476,
    name: "Southampton",
  },
  {
    code: "E06000046",
    latitude: 50.6757603454977,
    longitude: -1.2965571302661463,
    name: "Isle of Wight",
  },
  {
    code: "E06000047",
    latitude: 54.69546649509508,
    longitude: -1.8399221513180717,
    name: "County Durham",
  },
  {
    code: "E06000049",
    latitude: 53.180287735934854,
    longitude: -2.349941890981674,
    name: "Cheshire East",
  },
  {
    code: "E06000050",
    latitude: 53.20017134530418,
    longitude: -2.7358558624211353,
    name: "Cheshire West and Chester",
  },
  {
    code: "E06000051",
    latitude: 52.63491614538192,
    longitude: -2.734052016854389,
    name: "Shropshire",
  },
  {
    code: "E06000052",
    latitude: 50.40561018951315,
    longitude: -4.827253921476089,
    name: "Cornwall",
  },
  {
    code: "E06000053",
    latitude: 49.93692710817796,
    longitude: -6.3172329171934445,
    name: "Isles of Scilly",
  },
  {
    code: "E06000054",
    latitude: 51.297111501250974,
    longitude: -1.944599903860871,
    name: "Wiltshire",
  },
  {
    code: "E06000055",
    latitude: 52.18919310809463,
    longitude: -0.4770628001583052,
    name: "Bedford",
  },
  {
    code: "E06000056",
    latitude: 52.00663976892435,
    longitude: -0.43626725158880253,
    name: "Central Bedfordshire",
  },
  {
    code: "E06000057",
    latitude: 55.24151465737731,
    longitude: -2.066353915262929,
    name: "Northumberland",
  },
  {
    code: "E06000058",
    latitude: 50.74700732414777,
    longitude: -1.8832681496357793,
    name: "Bournemouth, Christchurch and Poole",
  },
  {
    code: "E06000059",
    latitude: 50.80635798849874,
    longitude: -2.333552688073392,
    name: "Dorset",
  },
  {
    code: "E06000060",
    latitude: 51.78497051990805,
    longitude: -0.811548347145609,
    name: "Buckinghamshire",
  },
  {
    code: "E06000061",
    latitude: 52.425506664718796,
    longitude: -0.6260372254300591,
    name: "North Northamptonshire",
  },
  {
    code: "E06000062",
    latitude: 52.222835093139764,
    longitude: -1.0363295726175195,
    name: "West Northamptonshire",
  },
  {
    code: "E07000008",
    latitude: 52.19938182667713,
    longitude: 0.1329515114604446,
    name: "Cambridge",
  },
  {
    code: "E07000009",
    latitude: 52.34293324152644,
    longitude: 0.2948066313658518,
    name: "East Cambridgeshire",
  },
  {
    code: "E07000010",
    latitude: 52.562858939509525,
    longitude: 0.0607896957372467,
    name: "Fenland",
  },
  {
    code: "E07000011",
    latitude: 52.37362923237915,
    longitude: -0.22075343971133807,
    name: "Huntingdonshire",
  },
  {
    code: "E07000012",
    latitude: 52.17280212546599,
    longitude: 0.08132859576750119,
    name: "South Cambridgeshire",
  },
  {
    code: "E07000026",
    latitude: 54.71109661471904,
    longitude: -3.247237499866813,
    name: "Allerdale",
  },
  {
    code: "E07000027",
    latitude: 54.12656550139146,
    longitude: -3.2122825349853583,
    name: "Barrow-in-Furness",
  },
  {
    code: "E07000028",
    latitude: 54.97091383473914,
    longitude: -2.8094436092502293,
    name: "Carlisle",
  },
  {
    code: "E07000029",
    latitude: 54.413880084231444,
    longitude: -3.3731272077234777,
    name: "Copeland",
  },
  {
    code: "E07000030",
    latitude: 54.61278680335264,
    longitude: -2.6218267981035446,
    name: "Eden",
  },
  {
    code: "E07000031",
    latitude: 54.2927047358238,
    longitude: -2.8500779218148105,
    name: "South Lakeland",
  },
  {
    code: "E07000032",
    latitude: 53.02925447603885,
    longitude: -1.4666568649512144,
    name: "Amber Valley",
  },
  {
    code: "E07000033",
    latitude: 53.21564515722299,
    longitude: -1.277323438915695,
    name: "Bolsover",
  },
  {
    code: "E07000034",
    latitude: 53.25629944895602,
    longitude: -1.401228741357202,
    name: "Chesterfield",
  },
  {
    code: "E07000035",
    latitude: 53.12816811606085,
    longitude: -1.693677893924122,
    name: "Derbyshire Dales",
  },
  {
    code: "E07000036",
    latitude: 52.93550891009125,
    longitude: -1.346692081023443,
    name: "Erewash",
  },
  {
    code: "E07000037",
    latitude: 53.37296641244903,
    longitude: -1.8739366932967325,
    name: "High Peak",
  },
  {
    code: "E07000038",
    latitude: 53.225847678708305,
    longitude: -1.4482868227432952,
    name: "North East Derbyshire",
  },
  {
    code: "E07000039",
    latitude: 52.83101085121445,
    longitude: -1.559541226762586,
    name: "South Derbyshire",
  },
  {
    code: "E07000040",
    latitude: 50.7644144238183,
    longitude: -3.2242607389603877,
    name: "East Devon",
  },
  {
    code: "E07000041",
    latitude: 50.721627040872086,
    longitude: -3.510194731681634,
    name: "Exeter",
  },
  {
    code: "E07000042",
    latitude: 50.87625310438998,
    longitude: -3.5564536432802596,
    name: "Mid Devon",
  },
  {
    code: "E07000043",
    latitude: 51.06978051449705,
    longitude: -3.9145894615151837,
    name: "North Devon",
  },
  {
    code: "E07000044",
    latitude: 50.37400242434515,
    longitude: -3.8229726900418806,
    name: "South Hams",
  },
  {
    code: "E07000045",
    latitude: 50.606292810414004,
    longitude: -3.656775260152646,
    name: "Teignbridge",
  },
  {
    code: "E07000046",
    latitude: 50.88735997270817,
    longitude: -4.25077958542708,
    name: "Torridge",
  },
  {
    code: "E07000047",
    latitude: 50.656081600175575,
    longitude: -4.0462413429110855,
    name: "West Devon",
  },
  {
    code: "E07000061",
    latitude: 50.776225481391094,
    longitude: 0.26625766989961985,
    name: "Eastbourne",
  },
  {
    code: "E07000062",
    latitude: 50.86936034488372,
    longitude: 0.5704621674389843,
    name: "Hastings",
  },
  {
    code: "E07000063",
    latitude: 50.8821072975825,
    longitude: 0.0050748098947772605,
    name: "Lewes",
  },
  {
    code: "E07000064",
    latitude: 50.95166753441483,
    longitude: 0.5389146002775347,
    name: "Rother",
  },
  {
    code: "E07000065",
    latitude: 50.967367515225746,
    longitude: 0.19868125521520388,
    name: "Wealden",
  },
  {
    code: "E07000066",
    latitude: 51.590134869319385,
    longitude: 0.4663226092843456,
    name: "Basildon",
  },
  {
    code: "E07000067",
    latitude: 51.94183879751688,
    longitude: 0.5899614348193123,
    name: "Braintree",
  },
  {
    code: "E07000068",
    latitude: 51.638637955561244,
    longitude: 0.3107869087967995,
    name: "Brentwood",
  },
  {
    code: "E07000069",
    latitude: 51.53641189475353,
    longitude: 0.5946211542285319,
    name: "Castle Point",
  },
  {
    code: "E07000070",
    latitude: 51.725434120995246,
    longitude: 0.48094112526619526,
    name: "Chelmsford",
  },
  {
    code: "E07000071",
    latitude: 51.87253282045976,
    longitude: 0.8609309591403655,
    name: "Colchester",
  },
  {
    code: "E07000072",
    latitude: 51.708882384257635,
    longitude: 0.1486915695679168,
    name: "Epping Forest",
  },
  {
    code: "E07000073",
    latitude: 51.76835476680498,
    longitude: 0.10856657698355468,
    name: "Harlow",
  },
  {
    code: "E07000074",
    latitude: 51.70946993479704,
    longitude: 0.7838011929162512,
    name: "Maldon",
  },
  {
    code: "E07000075",
    latitude: 51.591374099021124,
    longitude: 0.8092613792210698,
    name: "Rochford",
  },
  {
    code: "E07000076",
    latitude: 51.87132374859949,
    longitude: 1.1150496821120124,
    name: "Tendring",
  },
  {
    code: "E07000077",
    latitude: 51.93460826389954,
    longitude: 0.2798318323627599,
    name: "Uttlesford",
  },
  {
    code: "E07000078",
    latitude: 51.89700690276148,
    longitude: -2.0740151810385865,
    name: "Cheltenham",
  },
  {
    code: "E07000079",
    latitude: 51.81645856854139,
    longitude: -1.8929419354112125,
    name: "Cotswold",
  },
  {
    code: "E07000080",
    latitude: 51.82842926551676,
    longitude: -2.490025572472003,
    name: "Forest of Dean",
  },
  {
    code: "E07000081",
    latitude: 51.849379234494876,
    longitude: -2.240261492324201,
    name: "Gloucester",
  },
  {
    code: "E07000082",
    latitude: 51.729269112006875,
    longitude: -2.3001456887770413,
    name: "Stroud",
  },
  {
    code: "E07000083",
    latitude: 51.94562381915936,
    longitude: -2.0874378662587785,
    name: "Tewkesbury",
  },
  {
    code: "E07000084",
    latitude: 51.27345847224348,
    longitude: -1.2009247727511283,
    name: "Basingstoke and Deane",
  },
  {
    code: "E07000085",
    latitude: 51.07454940411968,
    longitude: -0.9551085990199182,
    name: "East Hampshire",
  },
  {
    code: "E07000086",
    latitude: 50.93300477813201,
    longitude: -1.3271878694786154,
    name: "Eastleigh",
  },
  {
    code: "E07000087",
    latitude: 50.85263451502312,
    longitude: -1.2246507197683223,
    name: "Fareham",
  },
  {
    code: "E07000088",
    latitude: 50.80520335621478,
    longitude: -1.1594503927666477,
    name: "Gosport",
  },
  {
    code: "E07000089",
    latitude: 51.28481136602524,
    longitude: -0.8974554963887682,
    name: "Hart",
  },
  {
    code: "E07000090",
    latitude: 50.83838130999473,
    longitude: -0.9893462776796569,
    name: "Havant",
  },
  {
    code: "E07000091",
    latitude: 50.858492573637946,
    longitude: -1.624739719700686,
    name: "New Forest",
  },
  {
    code: "E07000092",
    latitude: 51.27344225763752,
    longitude: -0.7674634690459174,
    name: "Rushmoor",
  },
  {
    code: "E07000093",
    latitude: 51.12842936818371,
    longitude: -1.5106415161850355,
    name: "Test Valley",
  },
  {
    code: "E07000094",
    latitude: 51.02815901161257,
    longitude: -1.2210600252506847,
    name: "Winchester",
  },
  {
    code: "E07000095",
    latitude: 51.725114628284814,
    longitude: -0.04734962913403197,
    name: "Broxbourne",
  },
  {
    code: "E07000096",
    latitude: 51.778190124766475,
    longitude: -0.5408541893714901,
    name: "Dacorum",
  },
  {
    code: "E07000098",
    latitude: 51.67728912837812,
    longitude: -0.28107174750356295,
    name: "Hertsmere",
  },
  {
    code: "E07000099",
    latitude: 51.959101496745404,
    longitude: -0.19267692202282824,
    name: "North Hertfordshire",
  },
  {
    code: "E07000102",
    latitude: 51.660971776742144,
    longitude: -0.4605774694275029,
    name: "Three Rivers",
  },
  {
    code: "E07000103",
    latitude: 51.66808801982215,
    longitude: -0.40332909571099784,
    name: "Watford",
  },
  {
    code: "E07000105",
    latitude: 51.1252936288187,
    longitude: 0.8113290011824823,
    name: "Ashford",
  },
  {
    code: "E07000106",
    latitude: 51.28323129468354,
    longitude: 1.1018334332879764,
    name: "Canterbury",
  },
  {
    code: "E07000107",
    latitude: 51.43145260453194,
    longitude: 0.25319617048001386,
    name: "Dartford",
  },
  {
    code: "E07000108",
    latitude: 51.21228721455224,
    longitude: 1.2892442792602081,
    name: "Dover",
  },
  {
    code: "E07000109",
    latitude: 51.40512694379029,
    longitude: 0.3950230079304409,
    name: "Gravesham",
  },
  {
    code: "E07000110",
    latitude: 51.234996956402505,
    longitude: 0.580582473810608,
    name: "Maidstone",
  },
  {
    code: "E07000111",
    latitude: 51.275408890717095,
    longitude: 0.1719997032813176,
    name: "Sevenoaks",
  },
  {
    code: "E07000112",
    latitude: 51.053891031171375,
    longitude: 0.9941582333337154,
    name: "Folkestone and Hythe",
  },
  {
    code: "E07000113",
    latitude: 51.34166605780736,
    longitude: 0.8132419335408031,
    name: "Swale",
  },
  {
    code: "E07000114",
    latitude: 51.35453441313606,
    longitude: 1.3405796859663377,
    name: "Thanet",
  },
  {
    code: "E07000115",
    latitude: 51.273616313383464,
    longitude: 0.3578648325877851,
    name: "Tonbridge and Malling",
  },
  {
    code: "E07000116",
    latitude: 51.114395660623806,
    longitude: 0.4308532854200606,
    name: "Tunbridge Wells",
  },
  {
    code: "E07000117",
    latitude: 53.77894958995637,
    longitude: -2.2219458719860334,
    name: "Burnley",
  },
  {
    code: "E07000118",
    latitude: 53.66149967024434,
    longitude: -2.6478785142094536,
    name: "Chorley",
  },
  {
    code: "E07000119",
    latitude: 53.78411123388117,
    longitude: -2.922131686814459,
    name: "Fylde",
  },
  {
    code: "E07000120",
    latitude: 53.75866220451823,
    longitude: -2.3897829425617867,
    name: "Hyndburn",
  },
  {
    code: "E07000121",
    latitude: 54.06900162021673,
    longitude: -2.7147423207089125,
    name: "Lancaster",
  },
  {
    code: "E07000122",
    latitude: 53.865508379802854,
    longitude: -2.1849329305906404,
    name: "Pendle",
  },
  {
    code: "E07000123",
    latitude: 53.81181064622423,
    longitude: -2.6976497327913247,
    name: "Preston",
  },
  {
    code: "E07000124",
    latitude: 53.90723477782353,
    longitude: -2.444757931966501,
    name: "Ribble Valley",
  },
  {
    code: "E07000125",
    latitude: 53.69383090626743,
    longitude: -2.26729863730398,
    name: "Rossendale",
  },
  {
    code: "E07000126",
    latitude: 53.72814879735231,
    longitude: -2.7102546836195924,
    name: "South Ribble",
  },
  {
    code: "E07000127",
    latitude: 53.609546385050265,
    longitude: -2.88244518157037,
    name: "West Lancashire",
  },
  {
    code: "E07000128",
    latitude: 53.90296057480038,
    longitude: -2.8538756506994405,
    name: "Wyre",
  },
  {
    code: "E07000129",
    latitude: 52.57152587914124,
    longitude: -1.2219523462657604,
    name: "Blaby",
  },
  {
    code: "E07000130",
    latitude: 52.73702612196434,
    longitude: -1.146128893050447,
    name: "Charnwood",
  },
  {
    code: "E07000131",
    latitude: 52.53932706787488,
    longitude: -1.0081130451288947,
    name: "Harborough",
  },
  {
    code: "E07000132",
    latitude: 52.61650029197995,
    longitude: -1.3910456553992232,
    name: "Hinckley and Bosworth",
  },
  {
    code: "E07000133",
    latitude: 52.79640211952038,
    longitude: -0.8562111442038254,
    name: "Melton",
  },
  {
    code: "E07000134",
    latitude: 52.753135697166584,
    longitude: -1.4051140561558733,
    name: "North West Leicestershire",
  },
  {
    code: "E07000135",
    latitude: 52.587155129254384,
    longitude: -1.0933986857523386,
    name: "Oadby and Wigston",
  },
  {
    code: "E07000136",
    latitude: 52.97418176028267,
    longitude: -0.022338950316428283,
    name: "Boston",
  },
  {
    code: "E07000137",
    latitude: 53.24937531778262,
    longitude: 0.030335415497198426,
    name: "East Lindsey",
  },
  {
    code: "E07000138",
    latitude: 53.224748287084495,
    longitude: -0.5549532897222466,
    name: "Lincoln",
  },
  {
    code: "E07000139",
    latitude: 53.081103895768045,
    longitude: -0.4459213148263784,
    name: "North Kesteven",
  },
  {
    code: "E07000140",
    latitude: 52.79757857494909,
    longitude: -0.03227880604796555,
    name: "South Holland",
  },
  {
    code: "E07000141",
    latitude: 52.85431157732312,
    longitude: -0.5178339655994463,
    name: "South Kesteven",
  },
  {
    code: "E07000142",
    latitude: 53.3927775645803,
    longitude: -0.477116629039996,
    name: "West Lindsey",
  },
  {
    code: "E07000143",
    latitude: 52.58795154184792,
    longitude: 0.8372976875398038,
    name: "Breckland",
  },
  {
    code: "E07000144",
    latitude: 52.70122121118887,
    longitude: 1.3106393770830709,
    name: "Broadland",
  },
  {
    code: "E07000145",
    latitude: 52.640740913909134,
    longitude: 1.6614166691074645,
    name: "Great Yarmouth",
  },
  {
    code: "E07000146",
    latitude: 52.72668275740303,
    longitude: 0.4704795581499244,
    name: "King's Lynn and West Norfolk",
  },
  {
    code: "E07000147",
    latitude: 52.85050601595053,
    longitude: 1.169852341059246,
    name: "North Norfolk",
  },
  {
    code: "E07000148",
    latitude: 52.631854392898724,
    longitude: 1.282822926611926,
    name: "Norwich",
  },
  {
    code: "E07000149",
    latitude: 52.51459629281232,
    longitude: 1.2760224360271584,
    name: "South Norfolk",
  },
  {
    code: "E07000163",
    latitude: 54.0798401487005,
    longitude: -2.1742545658221903,
    name: "Craven",
  },
  {
    code: "E07000164",
    latitude: 54.28678945827094,
    longitude: -1.3190984000408648,
    name: "Hambleton",
  },
  {
    code: "E07000165",
    latitude: 54.06711593314657,
    longitude: -1.5955457298939852,
    name: "Harrogate",
  },
  {
    code: "E07000166",
    latitude: 54.361551811372095,
    longitude: -1.934332959800723,
    name: "Richmondshire",
  },
  {
    code: "E07000167",
    latitude: 54.21050883554238,
    longitude: -0.8520189902354132,
    name: "Ryedale",
  },
  {
    code: "E07000168",
    latitude: 54.36649941627145,
    longitude: -0.6349859492117146,
    name: "Scarborough",
  },
  {
    code: "E07000169",
    latitude: 53.78706980667383,
    longitude: -1.1346853420720464,
    name: "Selby",
  },
  {
    code: "E07000170",
    latitude: 53.092277037538466,
    longitude: -1.2591490508717247,
    name: "Ashfield",
  },
  {
    code: "E07000171",
    latitude: 53.33279141206404,
    longitude: -0.9589164676130396,
    name: "Bassetlaw",
  },
  {
    code: "E07000172",
    latitude: 52.976440594553225,
    longitude: -1.2620924781375797,
    name: "Broxtowe",
  },
  {
    code: "E07000173",
    latitude: 53.02839128745366,
    longitude: -1.1193069955757413,
    name: "Gedling",
  },
  {
    code: "E07000174",
    latitude: 53.170733647924045,
    longitude: -1.1727851249983834,
    name: "Mansfield",
  },
  {
    code: "E07000175",
    latitude: 53.123970951845024,
    longitude: -0.9197414153086061,
    name: "Newark and Sherwood",
  },
  {
    code: "E07000176",
    latitude: 52.90086871927043,
    longitude: -1.0377314701159452,
    name: "Rushcliffe",
  },
  {
    code: "E07000177",
    latitude: 51.95879261561185,
    longitude: -1.275578025693181,
    name: "Cherwell",
  },
  {
    code: "E07000178",
    latitude: 51.754591971700485,
    longitude: -1.2409443908189564,
    name: "Oxford",
  },
  {
    code: "E07000179",
    latitude: 51.633753370468405,
    longitude: -1.0713877067426614,
    name: "South Oxfordshire",
  },
  {
    code: "E07000180",
    latitude: 51.63423466130708,
    longitude: -1.4416520244982471,
    name: "Vale of White Horse",
  },
  {
    code: "E07000181",
    latitude: 51.83757679639015,
    longitude: -1.5069146700847698,
    name: "West Oxfordshire",
  },
  {
    code: "E07000187",
    latitude: 51.20290701912398,
    longitude: -2.5446562311798244,
    name: "Mendip",
  },
  {
    code: "E07000188",
    latitude: 51.18446882892626,
    longitude: -2.9528668284729407,
    name: "Sedgemoor",
  },
  {
    code: "E07000189",
    latitude: 50.988142389326185,
    longitude: -2.709893161309869,
    name: "South Somerset",
  },
  {
    code: "E07000192",
    latitude: 52.71239945810714,
    longitude: -1.9802287324835937,
    name: "Cannock Chase",
  },
  {
    code: "E07000193",
    latitude: 52.86328535003277,
    longitude: -1.8121379584306287,
    name: "East Staffordshire",
  },
  {
    code: "E07000194",
    latitude: 52.688186035415896,
    longitude: -1.8049084603198353,
    name: "Lichfield",
  },
  {
    code: "E07000195",
    latitude: 52.993140815126814,
    longitude: -2.3188748458041464,
    name: "Newcastle-under-Lyme",
  },
  {
    code: "E07000196",
    latitude: 52.62541137603058,
    longitude: -2.176804614625785,
    name: "South Staffordshire",
  },
  {
    code: "E07000197",
    latitude: 52.84816898938671,
    longitude: -2.176494751239732,
    name: "Stafford",
  },
  {
    code: "E07000198",
    latitude: 53.0793326469223,
    longitude: -1.9812508239040765,
    name: "Staffordshire Moorlands",
  },
  {
    code: "E07000199",
    latitude: 52.6264453128355,
    longitude: -1.6778379725094381,
    name: "Tamworth",
  },
  {
    code: "E07000200",
    latitude: 52.050640645543815,
    longitude: 0.9153466405413693,
    name: "Babergh",
  },
  {
    code: "E07000202",
    latitude: 52.055891759444606,
    longitude: 1.159809510424987,
    name: "Ipswich",
  },
  {
    code: "E07000203",
    latitude: 52.246838725751175,
    longitude: 1.0924468154346019,
    name: "Mid Suffolk",
  },
  {
    code: "E07000207",
    latitude: 51.35713100365546,
    longitude: -0.3921507681709441,
    name: "Elmbridge",
  },
  {
    code: "E07000208",
    latitude: 51.33916570108228,
    longitude: -0.2614239258532292,
    name: "Epsom and Ewell",
  },
  {
    code: "E07000209",
    latitude: 51.24779615432025,
    longitude: -0.5616245833821756,
    name: "Guildford",
  },
  {
    code: "E07000210",
    latitude: 51.2122622210417,
    longitude: -0.32509233042516017,
    name: "Mole Valley",
  },
  {
    code: "E07000211",
    latitude: 51.25319918770127,
    longitude: -0.19182850997979578,
    name: "Reigate and Banstead",
  },
  {
    code: "E07000212",
    latitude: 51.39378659769547,
    longitude: -0.541291330753056,
    name: "Runnymede",
  },
  {
    code: "E07000213",
    latitude: 51.42480195961151,
    longitude: -0.4679633475001294,
    name: "Spelthorne",
  },
  {
    code: "E07000214",
    latitude: 51.34292764121842,
    longitude: -0.6705307848323258,
    name: "Surrey Heath",
  },
  {
    code: "E07000215",
    latitude: 51.227029652754425,
    longitude: -0.04338017462062135,
    name: "Tandridge",
  },
  {
    code: "E07000216",
    latitude: 51.14982501745559,
    longitude: -0.6326458406087355,
    name: "Waverley",
  },
  {
    code: "E07000217",
    latitude: 51.31387716964214,
    longitude: -0.5607507815799652,
    name: "Woking",
  },
  {
    code: "E07000218",
    latitude: 52.54749725942545,
    longitude: -1.6280456571697905,
    name: "North Warwickshire",
  },
  {
    code: "E07000219",
    latitude: 52.50114562125278,
    longitude: -1.4758632400992873,
    name: "Nuneaton and Bedworth",
  },
  {
    code: "E07000220",
    latitude: 52.38437811474208,
    longitude: -1.3282826704229385,
    name: "Rugby",
  },
  {
    code: "E07000221",
    latitude: 52.17879981695473,
    longitude: -1.6228278530216431,
    name: "Stratford-on-Avon",
  },
  {
    code: "E07000222",
    latitude: 52.310017829103124,
    longitude: -1.5846531916445654,
    name: "Warwick",
  },
  {
    code: "E07000223",
    latitude: 50.84443438050129,
    longitude: -0.2985040092533094,
    name: "Adur",
  },
  {
    code: "E07000224",
    latitude: 50.840865602674924,
    longitude: -0.5681658978804546,
    name: "Arun",
  },
  {
    code: "E07000225",
    latitude: 50.93741536933114,
    longitude: -0.7422440040560065,
    name: "Chichester",
  },
  {
    code: "E07000226",
    latitude: 51.1254661700113,
    longitude: -0.18200728705550992,
    name: "Crawley",
  },
  {
    code: "E07000227",
    latitude: 50.993754178312514,
    longitude: -0.3663074246342236,
    name: "Horsham",
  },
  {
    code: "E07000228",
    latitude: 51.02083886423299,
    longitude: -0.1270243716013992,
    name: "Mid Sussex",
  },
  {
    code: "E07000229",
    latitude: 50.82919814459707,
    longitude: -0.39578661953785454,
    name: "Worthing",
  },
  {
    code: "E07000234",
    latitude: 52.36176528779462,
    longitude: -2.0160073368084706,
    name: "Bromsgrove",
  },
  {
    code: "E07000235",
    latitude: 52.182770161131685,
    longitude: -2.3446628742896687,
    name: "Malvern Hills",
  },
  {
    code: "E07000236",
    latitude: 52.28229985166204,
    longitude: -1.9518529665093296,
    name: "Redditch",
  },
  {
    code: "E07000237",
    latitude: 52.19670514669267,
    longitude: -2.211320153774252,
    name: "Worcester",
  },
  {
    code: "E07000238",
    latitude: 52.164199691037105,
    longitude: -2.050810022503635,
    name: "Wychavon",
  },
  {
    code: "E07000239",
    latitude: 52.37959031574174,
    longitude: -2.27466314222408,
    name: "Wyre Forest",
  },
  {
    code: "E07000240",
    latitude: 51.77206785703726,
    longitude: -0.3424709347015649,
    name: "St Albans",
  },
  {
    code: "E07000241",
    latitude: 51.766895043191354,
    longitude: -0.19406096934250514,
    name: "Welwyn Hatfield",
  },
  {
    code: "E07000242",
    latitude: 51.865063283193116,
    longitude: -0.00822946374427719,
    name: "East Hertfordshire",
  },
  {
    code: "E07000243",
    latitude: 51.90432218635355,
    longitude: -0.18930873732611708,
    name: "Stevenage",
  },
  {
    code: "E07000244",
    latitude: 52.237035513808564,
    longitude: 1.4614866822021453,
    name: "East Suffolk",
  },
  {
    code: "E07000245",
    latitude: 52.27772925028529,
    longitude: 0.6221063581243167,
    name: "West Suffolk",
  },
  {
    code: "E07000246",
    latitude: 51.086902205760076,
    longitude: -3.3625885934651984,
    name: "Somerset West and Taunton",
  },
  {
    code: "E08000001",
    latitude: 53.57864856223228,
    longitude: -2.4669684537297805,
    name: "Bolton",
  },
  {
    code: "E08000002",
    latitude: 53.587854869907005,
    longitude: -2.312551711924447,
    name: "Bury",
  },
  {
    code: "E08000003",
    latitude: 53.45150077848892,
    longitude: -2.232522729622829,
    name: "Manchester",
  },
  {
    code: "E08000004",
    latitude: 53.55190458926331,
    longitude: -2.0506501665254886,
    name: "Oldham",
  },
  {
    code: "E08000005",
    latitude: 53.616569089614025,
    longitude: -2.1578663706863463,
    name: "Rochdale",
  },
  {
    code: "E08000006",
    latitude: 53.48955525768136,
    longitude: -2.37018934884624,
    name: "Salford",
  },
  {
    code: "E08000007",
    latitude: 53.393977865812516,
    longitude: -2.128107730185501,
    name: "Stockport",
  },
  {
    code: "E08000008",
    latitude: 53.47748820024293,
    longitude: -2.0628135337581566,
    name: "Tameside",
  },
  {
    code: "E08000009",
    latitude: 53.41901205993216,
    longitude: -2.355349924104002,
    name: "Trafford",
  },
  {
    code: "E08000010",
    latitude: 53.524164642448405,
    longitude: -2.5895081771355746,
    name: "Wigan",
  },
  {
    code: "E08000011",
    latitude: 53.425116491486804,
    longitude: -2.834037085481898,
    name: "Knowsley",
  },
  {
    code: "E08000012",
    latitude: 53.395468433530894,
    longitude: -2.9170077634824834,
    name: "Liverpool",
  },
  {
    code: "E08000013",
    latitude: 53.45948860927965,
    longitude: -2.723619945305964,
    name: "St. Helens",
  },
  {
    code: "E08000014",
    latitude: 53.565395787862684,
    longitude: -3.015013797340246,
    name: "Sefton",
  },
  {
    code: "E08000015",
    latitude: 53.36835911445352,
    longitude: -3.0926761409330568,
    name: "Wirral",
  },
  {
    code: "E08000016",
    latitude: 53.53588815046186,
    longitude: -1.532575872469794,
    name: "Barnsley",
  },
  {
    code: "E08000017",
    latitude: 53.54432224751319,
    longitude: -1.0957737193411996,
    name: "Doncaster",
  },
  {
    code: "E08000018",
    latitude: 53.408637208432076,
    longitude: -1.2861509467057193,
    name: "Rotherham",
  },
  {
    code: "E08000019",
    latitude: 53.40443222849486,
    longitude: -1.550380181900138,
    name: "Sheffield",
  },
  {
    code: "E08000021",
    latitude: 55.007672794503286,
    longitude: -1.6578303684504707,
    name: "Newcastle upon Tyne",
  },
  {
    code: "E08000022",
    latitude: 55.02804809181996,
    longitude: -1.5174516535895153,
    name: "North Tyneside",
  },
  {
    code: "E08000023",
    latitude: 54.9635371516944,
    longitude: -1.4442661611332397,
    name: "South Tyneside",
  },
  {
    code: "E08000024",
    latitude: 54.88040764637263,
    longitude: -1.4519661917896463,
    name: "Sunderland",
  },
  {
    code: "E08000025",
    latitude: 52.488561725953,
    longitude: -1.874797877687481,
    name: "Birmingham",
  },
  {
    code: "E08000026",
    latitude: 52.41688482792924,
    longitude: -1.5197416919496904,
    name: "Coventry",
  },
  {
    code: "E08000027",
    latitude: 52.482865399299314,
    longitude: -2.1087303387130785,
    name: "Dudley",
  },
  {
    code: "E08000028",
    latitude: 52.51544261465795,
    longitude: -2.0089668956672924,
    name: "Sandwell",
  },
  {
    code: "E08000029",
    latitude: 52.41313150292302,
    longitude: -1.727069398633857,
    name: "Solihull",
  },
  {
    code: "E08000030",
    latitude: 52.5992715929521,
    longitude: -1.9663424583620592,
    name: "Walsall",
  },
  {
    code: "E08000031",
    latitude: 52.58920582143699,
    longitude: -2.1209851969450706,
    name: "Wolverhampton",
  },
  {
    code: "E08000032",
    latitude: 53.84468482240264,
    longitude: -1.8546738676553713,
    name: "Bradford",
  },
  {
    code: "E08000033",
    latitude: 53.72119484454706,
    longitude: -1.9734139640237733,
    name: "Calderdale",
  },
  {
    code: "E08000034",
    latitude: 53.62737186318633,
    longitude: -1.763308915131283,
    name: "Kirklees",
  },
  {
    code: "E08000035",
    latitude: 53.82576254424485,
    longitude: -1.5060210099763407,
    name: "Leeds",
  },
  {
    code: "E08000036",
    latitude: 53.660691958151475,
    longitude: -1.4118100729235357,
    name: "Wakefield",
  },
  {
    code: "E08000037",
    latitude: 54.93537551018429,
    longitude: -1.6828989285238778,
    name: "Gateshead",
  },
  {
    code: "E09000001",
    latitude: 51.51441262454095,
    longitude: -0.09241903556631997,
    name: "City of London",
  },
  {
    code: "E09000002",
    latitude: 51.54397211623516,
    longitude: 0.133191179099071,
    name: "Barking and Dagenham",
  },
  {
    code: "E09000003",
    latitude: 51.61602308690536,
    longitude: -0.20998443210428322,
    name: "Barnet",
  },
  {
    code: "E09000004",
    latitude: 51.46088647152611,
    longitude: 0.14236989836268835,
    name: "Bexley",
  },
  {
    code: "E09000005",
    latitude: 51.55855118288903,
    longitude: -0.2677860067945011,
    name: "Brent",
  },
  {
    code: "E09000006",
    latitude: 51.371982174453024,
    longitude: 0.05156055825328843,
    name: "Bromley",
  },
  {
    code: "E09000007",
    latitude: 51.54639106212335,
    longitude: -0.15738829251358466,
    name: "Camden",
  },
  {
    code: "E09000008",
    latitude: 51.35531961602265,
    longitude: -0.08714140260781518,
    name: "Croydon",
  },
  {
    code: "E09000009",
    latitude: 51.52247183180597,
    longitude: -0.3310065847175418,
    name: "Ealing",
  },
  {
    code: "E09000010",
    latitude: 51.65099414540596,
    longitude: -0.0872278768101179,
    name: "Enfield",
  },
  {
    code: "E09000011",
    latitude: 51.47442783055279,
    longitude: 0.055564516263643886,
    name: "Greenwich",
  },
  {
    code: "E09000012",
    latitude: 51.55226796055461,
    longitude: -0.0632910541669755,
    name: "Hackney",
  },
  {
    code: "E09000013",
    latitude: 51.49499378130542,
    longitude: -0.22074855500319532,
    name: "Hammersmith and Fulham",
  },
  {
    code: "E09000014",
    latitude: 51.590374849722615,
    longitude: -0.10744347016126003,
    name: "Haringey",
  },
  {
    code: "E09000015",
    latitude: 51.59771942731852,
    longitude: -0.341254851986308,
    name: "Harrow",
  },
  {
    code: "E09000016",
    latitude: 51.56310429541227,
    longitude: 0.2204531011468241,
    name: "Havering",
  },
  {
    code: "E09000017",
    latitude: 51.541471777925686,
    longitude: -0.44564055311654976,
    name: "Hillingdon",
  },
  {
    code: "E09000018",
    latitude: 51.46849539360366,
    longitude: -0.3661231937607835,
    name: "Hounslow",
  },
  {
    code: "E09000019",
    latitude: 51.54848453972744,
    longitude: -0.11021811351141253,
    name: "Islington",
  },
  {
    code: "E09000020",
    latitude: 51.5012070829512,
    longitude: -0.19218861287160888,
    name: "Kensington and Chelsea",
  },
  {
    code: "E09000021",
    latitude: 51.38788973154728,
    longitude: -0.2868881666376212,
    name: "Kingston upon Thames",
  },
  {
    code: "E09000022",
    latitude: 51.45383312034863,
    longitude: -0.11829293702695216,
    name: "Lambeth",
  },
  {
    code: "E09000023",
    latitude: 51.44827164847225,
    longitude: -0.020264823116060652,
    name: "Lewisham",
  },
  {
    code: "E09000024",
    latitude: 51.4099488874189,
    longitude: -0.19722519692786458,
    name: "Merton",
  },
  {
    code: "E09000025",
    latitude: 51.526887304966436,
    longitude: 0.03756570685246515,
    name: "Newham",
  },
  {
    code: "E09000026",
    latitude: 51.58565640660831,
    longitude: 0.07588889263574075,
    name: "Redbridge",
  },
  {
    code: "E09000027",
    latitude: 51.44273384981171,
    longitude: -0.3123566450313884,
    name: "Richmond upon Thames",
  },
  {
    code: "E09000028",
    latitude: 51.474230354534996,
    longitude: -0.07403245652373593,
    name: "Southwark",
  },
  {
    code: "E09000029",
    latitude: 51.362084252729076,
    longitude: -0.17754142460122857,
    name: "Sutton",
  },
  {
    code: "E09000030",
    latitude: 51.515739438194025,
    longitude: -0.03464527796851027,
    name: "Tower Hamlets",
  },
  {
    code: "E09000031",
    latitude: 51.594018892224014,
    longitude: -0.012610048394753645,
    name: "Waltham Forest",
  },
  {
    code: "E09000032",
    latitude: 51.45196404139301,
    longitude: -0.1861663071082827,
    name: "Wandsworth",
  },
  {
    code: "E09000033",
    latitude: 51.51344338051678,
    longitude: -0.16043098329787026,
    name: "Westminster",
  },
  {
    code: "N09000001",
    latitude: 54.70185842054202,
    longitude: -6.198733690739001,
    name: "Antrim and Newtownabbey",
  },
  {
    code: "N09000002",
    latitude: 54.36929265446641,
    longitude: -6.457651795104639,
    name: "Armagh City, Banbridge and Craigavon",
  },
  {
    code: "N09000003",
    latitude: 54.59643258079128,
    longitude: -5.9438121285206975,
    name: "Belfast",
  },
  {
    code: "N09000004",
    latitude: 55.06026600995258,
    longitude: -6.592483227400414,
    name: "Causeway Coast and Glens",
  },
  {
    code: "N09000005",
    latitude: 54.820348473224726,
    longitude: -7.361199617222389,
    name: "Derry City and Strabane",
  },
  {
    code: "N09000006",
    latitude: 54.44234396624049,
    longitude: -7.504580414244219,
    name: "Fermanagh and Omagh",
  },
  {
    code: "N09000007",
    latitude: 54.5083144119119,
    longitude: -6.053427377896163,
    name: "Lisburn and Castlereagh",
  },
  {
    code: "N09000008",
    latitude: 54.87445696621685,
    longitude: -6.102155992752313,
    name: "Mid and East Antrim",
  },
  {
    code: "N09000009",
    latitude: 54.62152385594948,
    longitude: -6.792842929789446,
    name: "Mid Ulster",
  },
  {
    code: "N09000010",
    latitude: 54.236331153652884,
    longitude: -6.069016216304619,
    name: "Newry, Mourne and Down",
  },
  {
    code: "N09000011",
    latitude: 54.53477624325786,
    longitude: -5.630081938129354,
    name: "Ards and North Down",
  },
  {
    code: "S12000005",
    latitude: 56.15046317043319,
    longitude: -3.7422156658592445,
    name: "Clackmannanshire",
  },
  {
    code: "S12000006",
    latitude: 55.07114737371524,
    longitude: -3.9594998733978817,
    name: "Dumfries and Galloway",
  },
  {
    code: "S12000008",
    latitude: 55.48501160996226,
    longitude: -4.314187218106304,
    name: "East Ayrshire",
  },
  {
    code: "S12000010",
    latitude: 55.94185652224377,
    longitude: -2.720655382901648,
    name: "East Lothian",
  },
  {
    code: "S12000011",
    latitude: 55.75313506442257,
    longitude: -4.359500235703768,
    name: "East Renfrewshire",
  },
  {
    code: "S12000013",
    latitude: 57.90753986470605,
    longitude: -6.874652063466372,
    name: "Na h-Eileanan Siar",
  },
  {
    code: "S12000014",
    latitude: 55.99733408367308,
    longitude: -3.7906354120887458,
    name: "Falkirk",
  },
  {
    code: "S12000017",
    latitude: 57.5729209093667,
    longitude: -4.771386541293899,
    name: "Highland",
  },
  {
    code: "S12000018",
    latitude: 55.9044004764088,
    longitude: -4.7426504469424975,
    name: "Inverclyde",
  },
  {
    code: "S12000019",
    latitude: 55.83222174230219,
    longitude: -3.1104220478884406,
    name: "Midlothian",
  },
  {
    code: "S12000020",
    latitude: 57.47550429643516,
    longitude: -3.2676121844203334,
    name: "Moray",
  },
  {
    code: "S12000021",
    latitude: 55.65051235447887,
    longitude: -4.9803779361491545,
    name: "North Ayrshire",
  },
  {
    code: "S12000023",
    latitude: 59.02921186463903,
    longitude: -3.035974477383477,
    name: "Orkney Islands",
  },
  {
    code: "S12000026",
    latitude: 55.576241234699424,
    longitude: -2.788470704183809,
    name: "Scottish Borders",
  },
  {
    code: "S12000027",
    latitude: 60.39487376646123,
    longitude: -1.2540339648170573,
    name: "Shetland Islands",
  },
  {
    code: "S12000028",
    latitude: 55.26828663904979,
    longitude: -4.689268504364702,
    name: "South Ayrshire",
  },
  {
    code: "S12000029",
    latitude: 55.61265195372158,
    longitude: -3.8106253375090398,
    name: "South Lanarkshire",
  },
  {
    code: "S12000030",
    latitude: 56.244005934437844,
    longitude: -4.340328252343077,
    name: "Stirling",
  },
  {
    code: "S12000033",
    latitude: 57.15576083252164,
    longitude: -2.1736425659657037,
    name: "Aberdeen City",
  },
  {
    code: "S12000034",
    latitude: 57.2411043731786,
    longitude: -2.631995245976201,
    name: "Aberdeenshire",
  },
  {
    code: "S12000035",
    latitude: 56.12188640444938,
    longitude: -5.502767691840644,
    name: "Argyll and Bute",
  },
  {
    code: "S12000036",
    latitude: 55.9264888968223,
    longitude: -3.2884036187071954,
    name: "City of Edinburgh",
  },
  {
    code: "S12000038",
    latitude: 55.847806231983675,
    longitude: -4.542787682457219,
    name: "Renfrewshire",
  },
  {
    code: "S12000039",
    latitude: 55.984872964591275,
    longitude: -4.517586873336872,
    name: "West Dunbartonshire",
  },
  {
    code: "S12000040",
    latitude: 55.884980021877475,
    longitude: -3.573783538414858,
    name: "West Lothian",
  },
  {
    code: "S12000041",
    latitude: 56.72395148675204,
    longitude: -2.917446749504437,
    name: "Angus",
  },
  {
    code: "S12000042",
    latitude: 56.477113025821254,
    longitude: -2.969664328890436,
    name: "Dundee City",
  },
  {
    code: "S12000045",
    latitude: 55.956027775725524,
    longitude: -4.225546232752389,
    name: "East Dunbartonshire",
  },
  {
    code: "S12000047",
    latitude: 56.22894504796966,
    longitude: -3.124866654205754,
    name: "Fife",
  },
  {
    code: "S12000048",
    latitude: 56.56538963869838,
    longitude: -3.818592348187117,
    name: "Perth and Kinross",
  },
  {
    code: "S12000049",
    latitude: 55.857875146908164,
    longitude: -4.253104532877866,
    name: "Glasgow City",
  },
  {
    code: "S12000050",
    latitude: 55.87475581498226,
    longitude: -3.9482424650854644,
    name: "North Lanarkshire",
  },
  {
    code: "W06000001",
    latitude: 53.28599462098552,
    longitude: -4.374494542370669,
    name: "Isle of Anglesey",
  },
  {
    code: "W06000002",
    latitude: 52.894681811362,
    longitude: -4.024298558871947,
    name: "Gwynedd",
  },
  {
    code: "W06000003",
    latitude: 53.14030968572184,
    longitude: -3.735788363247376,
    name: "Conwy",
  },
  {
    code: "W06000004",
    latitude: 53.09189572089837,
    longitude: -3.3540955846490497,
    name: "Denbighshire",
  },
  {
    code: "W06000005",
    latitude: 53.21787758130377,
    longitude: -3.16315071124856,
    name: "Flintshire",
  },
  {
    code: "W06000006",
    latitude: 52.99028440061084,
    longitude: -3.0178637193456574,
    name: "Wrexham",
  },
  {
    code: "W06000008",
    latitude: 52.25588138590091,
    longitude: -4.0731499906281465,
    name: "Ceredigion",
  },
  {
    code: "W06000009",
    latitude: 51.85362574201156,
    longitude: -4.887518202383304,
    name: "Pembrokeshire",
  },
  {
    code: "W06000010",
    latitude: 51.899639884797026,
    longitude: -4.16317221472056,
    name: "Carmarthenshire",
  },
  {
    code: "W06000011",
    latitude: 51.64157645017215,
    longitude: -4.061214157803198,
    name: "Swansea",
  },
  {
    code: "W06000012",
    latitude: 51.67970293923193,
    longitude: -3.7434084607189746,
    name: "Neath Port Talbot",
  },
  {
    code: "W06000013",
    latitude: 51.555848924875406,
    longitude: -3.6004033954848556,
    name: "Bridgend",
  },
  {
    code: "W06000014",
    latitude: 51.44474965739744,
    longitude: -3.3991746207064364,
    name: "Vale of Glamorgan",
  },
  {
    code: "W06000015",
    latitude: 51.50843953838191,
    longitude: -3.199593931230999,
    name: "Cardiff",
  },
  {
    code: "W06000016",
    latitude: 51.65033137503188,
    longitude: -3.4355594755763925,
    name: "Rhondda Cynon Taf",
  },
  {
    code: "W06000018",
    latitude: 51.653273033555756,
    longitude: -3.2049056379457594,
    name: "Caerphilly",
  },
  {
    code: "W06000019",
    latitude: 51.76674411093479,
    longitude: -3.19382744168345,
    name: "Blaenau Gwent",
  },
  {
    code: "W06000020",
    latitude: 51.69859016266428,
    longitude: -3.0534335948271636,
    name: "Torfaen",
  },
  {
    code: "W06000021",
    latitude: 51.75782075328696,
    longitude: -2.8673341314765137,
    name: "Monmouthshire",
  },
  {
    code: "W06000022",
    latitude: 51.5737651622625,
    longitude: -2.961790666377451,
    name: "Newport",
  },
  {
    code: "W06000023",
    latitude: 52.323755333542145,
    longitude: -3.4099314803326335,
    name: "Powys",
  },
  {
    code: "W06000024",
    latitude: 51.74094637319556,
    longitude: -3.3610681188782605,
    name: "Merthyr Tydfil",
  },
];
export const countriesWithImage = countries.filter((c) =>
  countryCodesWithImage.includes(c.code.toLowerCase())
);

export function getCountryName(language: string, country: Country) {
  return country.name;
}

export function sanitizeCountryName(countryName: string): string {
  return countryName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[- '()]/g, "")
    .toLowerCase();
}
